<template>
  <div class="serviceMain">
    <div class="searchBar">
      <div class="tittle"> DCI申领 </div>
      <div class="rightForm">
        <el-input
          v-model="form.subject"
          placeholder="请输入作品名称"
        ></el-input>
        <el-date-picker
          v-model="form.date"
           value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
       <el-button @click="fetchData">查询</el-button>
        <router-link :to="{path:'/DCIInputForm'}">
            <el-button type="primary">DCI申领</el-button>
        </router-link>
      </div>
    </div>

    <el-table
      :data="tableData"
      :cell-class-name="tabelCellClass"
      style="width: 100%"
    >
      <el-table-column
        prop="workName"
        label="作品名称"
         show-overflow-tooltip
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="dciCode"
        label="DCI"
        show-overflow-tooltip
        width="250"
      >
      </el-table-column>
      <el-table-column
        prop="createTimeText"
        label="创建时间"
        sortable
        width="170"
      >
        <!-- eslint-disable-next-line -->
        <template slot="header" slot-scope="scope">
          <div class="createTimeHeader">
            <span>创建时间</span>
            <a-tooltip>
              <template slot="title">
                prompt text
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        width="110"
      >
        <template slot-scope="scope">
          <span
            class="stausCell"
            :class="{'stausCell1':scope.row.status=='31',
            'stausCell2':scope.row.status=='40',
            'stausCell3':scope.row.status=='11'|| scope.row.status=='12' || scope.row.status=='13' || scope.row.status=='20',
            'stausCell4':scope.row.status=='10',
            'stausCell5':scope.row.status=='21',}"
          >
            {{scope.row.statusText}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        fixed="right"
      >
        <!-- eslint-disable-next-line -->
        <template slot-scope="scope">
          <router-link class="myDCIBtn" :to="{path:'/Mine/DCIDetail',query: {orderNo: scope.row.orderNo}}"> 详情 </router-link>
          <el-button class="myDCIBtn" @click="RepayAgain(scope.row)" v-if="scope.row.status=='10'"> 付款 </el-button>
          <el-button class="myDCIBtn" @click="getDCIImg(scope.row.certificateInfo.jpgUrl)" v-if="scope.row.status=='21'"> 查看证书 </el-button>
          <!-- <router-link class="myDCIBtn" :to="{path:'/DCIRegisterInputForm',query: {orderNo: scope.row.orderNo}}" v-if="scope.row.status=='21' && scope.row.registrationStatus == '00'"> 数登申请 </router-link> -->
          <router-link class="myDCIBtn" :to="{path:'/ComprehensiveInputForm',query: {formStep: '3',DCIOrderNo: scope.row.orderNo}}" v-if="scope.row.status=='21' && scope.row.registrationStatus == '00'"> 数登申请 </router-link>
          <el-button class="myDCIBtn" v-if="scope.row.status!='21' && scope.row.status!='10' && scope.row.status!='31' && scope.row.status!='40' && scope.row.registrationStatus == '00'" disabled> 数登申请 </el-button>
          <!-- <el-button class="myDCIBtn" v-if="scope.row.status!='21' && scope.row.status!='10'"> 申述 </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
     :total="pagination.total"
      :current-page.sync="pagination.page"
      :page-size.sync="pagination.pageSize"
      @size-change="sizeChange"
      @current-change="currentChange"
      :pagerCount="5" 
    >
    </el-pagination>


      <!-- 图片预览 -->
      <el-image-viewer
        v-if="showImageViewer"
        :url-list="[showImageUrl]"
        hide-on-click-modal
        teleported
        :on-close="closePre"
      />
      <el-image-viewer
        v-if="showImageViewer2"
        :url-list="[showImageUrl2]"
        hide-on-click-modal
        teleported
        :on-close="closePre2"
      />

      <!-- 支付弹窗 -->
      <pay-dialog ref="payDialog" payForm="DCI" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import PayDialog from "../../../components/PayDialog";
import {queryDCIOrderList,queryRegisterOrder,queryExistOrder,queryDCIPaymentOrder} from '@/api/EvidenceService'
export default {
  name: 'MyRegister',
  components: {
      'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
      PayDialog
  },
  data () {
    return {
      form: {
         subject: null,
        date: null
      },
      pagination: {
         total:0,
        page: 1,
        pageSize: 10
      },
      tableData:[],
      
      showImageViewer: false,
      showImageUrl: '',
      showImageViewer2: false,
      showImageUrl2: '',
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    onTabelRowClick(row){
      this.$router.push({
        path: "/DuplicateCheck/Detail",
            query: {orderNo:row.orderNo}
      });
    },
    tabelCellClass ({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'certificate') {
        return 'certificateCell'
      }
    },
    getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '12':return '检测中';
          case '13':return '申领中';
          case '20':return '待处理';
          case '21':return '申领成功';
          case '31':return '申领失败';
          case '40':return '已取消';
        }
    },
    fetchData (param) {
        queryDCIOrderList(this.pagination.page,this.pagination.pageSize,this.form).then(({data})=>{
          // console.log('我的DCI列表：',data);
            for (let i = 0; i < data.list.length; i++) {
            const element = data.list[i];
             element.num=element.items.length;
             element.statusText=this.getStatus(element.status);
              element.createTimeText= this.dayjs(element.createTime*1000).format('YYYY-MM-DD HH:mm:ss ');
              element.repetitionRateText = (element.repetitionRate * 100).toFixed(2) + "%"
      // this.orderInfo.updateTimeText= this.dayjs(this.orderInfo.updateTime*1000).format('YYYY-MM-DD HH:mm:ss ');       
              if(element.dciCode == ''){
                element.dciCode = '-'
              }  
          }
          this.tableData=data.list;
          this.pagination.total=data.total*1;

        });
    },
    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.fetchData()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.fetchData()
    },


    // 放大证书
    getRegisterImg(orderNo){
      queryRegisterOrder(orderNo).then(({ data }) => {
        // console.log("查重详情：",data);
        document.body.style.overflow = 'hidden';
        this.showImageUrl = data.certificateInfo.jpgUrl;
        this.showImageViewer = true;
      })
    },
    getDCIImg(url){
      document.body.style.overflow = 'hidden';
      this.showImageUrl = url;
      this.showImageViewer = true;
    },
    closePre(){
      document.body.style.overflow = 'auto';
      this.showImageUrl = '';
      this.showImageViewer = false;
    },


    // 获取存证图片
    getExistImg(orderNo){
      // console.log(orderNo);
      queryExistOrder(orderNo).then(({ data }) => {
        // console.log("存证详情：",data);
        document.body.style.overflow = 'hidden';
        this.showImageUrl2 = data.certificateInfo.jpgUrl;
        this.showImageViewer2 = true;
      })
    },
    closePre2(){
      document.body.style.overflow = 'auto';
      this.showImageUrl2 = '';
      this.showImageViewer2 = false;
    },

    //  下载报告
    download(zip){
      window.open(zip, "_self");  //_self，是不用打开新窗口
    },

    // 重新付款
    RepayAgain(row){
      // console.log('00000000000000--------------------',row);
      // 重新付款接口（不同于支付接口）
      queryDCIPaymentOrder(row.orderNo).then(({ data }) => {
        // that.order = data;
        // console.log("check::::::::::::",data);
        this.Info = data;
        // if (data.paymentStatus == "21") {   // 21是已经支付成功
        if (data.status == "12") {   // 12是已经支付成功，正在检测中
          this.bus.$emit("refreshVip");
          this.onSetPayDialogClose(true);
        } else {
          this.$refs.payDialog.showDialog(data);
          this.isPayDialogVisible = true;
        }
      });
    },
    onSetPayDialogClose(result) {
      console.log('支付成功result:',result);
      // 如果支付成功，跳转到详情页面
      if (result) {
        this.$router.push({
          path: "/Mine/MyCheck",
          // query: { orderNo: this.Info.orderNo },
        });
      }
    },

  }
}
</script>

<style lang='scss' scoped>
@import '../../../assets/css/mine.scss';
.myDCIBtn{color: #1884FF;background: transparent;padding: 0;border: none;margin: 0;display: inline-block;}
.myDCIBtn:nth-child(1),.myDCIBtn:nth-child(2){margin-right: 15px;}
.myDCIBtn:hover,.myDCIBtn:focus{background: transparent;}
.myDCIBtn2{background: transparent;padding: 0;border: none;margin: 0;display: inline-block;}
.myDCIBtn2:hover{background: transparent;color: #C0C4CC;}
.myDCIBtn:disabled{color: #C0C4CC;}
/deep/.cell{white-space: nowrap;}

	@media screen and (max-width: 991px){
    .serviceMain{padding: 20px;}
    .searchBar{display: block;}
    .searchBar .tittle{margin-bottom: 10px;}
  }
</style>
